
// import { APP_DOMAIN } from "../constants/constants";

import SignUp from "../pages/SignUp/SignUp";
import SplashScreen from "../pages/SplashScreen/SplashScreen";
import Discover from "../pages/Discover/Discover";
import Notification from "../pages/Notification/Notification";
import Premium from "../pages/Premium/Premium";
import HomeScreen from "../pages/HomeScreen";
import MarketScreen from "../pages/MarketScreen";

export const routePaths = {
  SplashScreen: '/',
  SignUp: '/signUp',
  Discover: '/discover',
  Notification : '/notification',
  Premium : '/premium',
  homeScreen:'/home',
  marketScreen:'/market',
};

export const freeRoutes = [
  routePaths.SplashScreen,
  routePaths.SignUp,
];

export const authRoutes = [
  {
    title: "Loading",
    path: routePaths.SplashScreen,
    url: routePaths.SplashScreen,
    exact: true,
    main: SplashScreen
  },
  {
    title: "Social Media",
    path: routePaths.SignUp,
    url: routePaths.SignUp,
    exact: true,
    main: SignUp
  },
  {
    title: "Discover",
    path: routePaths.Discover,
    url: routePaths.Discover, 
    exact: true,
    main: Discover
  },
  {
    title: "Notification",
    path: routePaths.Notification,
    url: routePaths.Notification,
    exact: true,
    main: Notification
  },
  {
    title: "Premium",
    path: routePaths.Premium,
    url: routePaths.Premium,
    exact: true,
    main: Premium
  },
  {
    title: "HomeScreen",
    path: routePaths.homeScreen,
    url: routePaths.homeScreen,
    exact: true,
    main: HomeScreen
  },
  {
    title: "MarketScreen",
    path: routePaths.marketScreen,
    url: routePaths.marketScreen,
    exact: false,
    main: MarketScreen
  },
];
import React from "react";
// import Div100vh from "react-div-100vh";

const PageLayout = ({ children, divClass, header }) => (
  <div
    className={`d-flex flex-column  ${divClass || ""}`}
  >
    {header}
    {children}
  </div>
);

export default PageLayout;

import React, { useEffect, useState, useRef } from "react";
import "./NewsListTabItem.scss";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BreakingBox from "../BreakingBox";
import News from "../../../../common/components/News/News";
import UniButton from "../../../../common/components/UniButton";
import CustomInfiniteScroll from "../../../../common/components/CustomInfiniteScroll/CustomInfiniteScroll";

const NewsListTabItem = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
 
NewsListTabItem.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

// export default function App() {
  const NewsTab = props => {
  const tabRef = useRef(null);
  const [value, setValue] = useState(0);
  const [showMore, setShowMore] = useState();

  const tabs = [
    {
        title: "Latest news",
        // allProps: {...a11yProps(0)},
    },
    {
      title: "News",
      // allProps: {...a11yProps(0)},
    },
    {
      title: "Culture",
      // allProps: {...a11yProps(0)},
    },
    {
      title: "Games",
      // allProps: {...a11yProps(0)},
    },
    {
      title: "Item Five",
      // allProps: {...a11yProps(0)},
    },
    {
      title: "Item Six",
      // allProps: {...a11yProps(0)},
    },
    {
      title: "Item seven",
      // allProps: {...a11yProps(0)},
    },
  ];

  const news = [
    {
      id: 1,
      title: "1 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 2,
      title: "2 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 3,
      title: "3 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 4,
      title: "4 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 5,
      title: "5 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 6,
      title: "6 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 7,
      title: "7 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 8,
      title: "8 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 9,
      title: "9 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 10,
      title: "10 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 11,
      title: "11 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 12,
      title: "12 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 13,
      title: "13 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 14,
      title: "14 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 15,
      title: "15 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 16,
      title: "16 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 17,
      title: "17 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 18,
      title: "18 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 19,
      title: "19 Most commen mistakes on selecting token for portfolio zzzzzzz",
      time: "3m ago",
    },
    {
      id: 20,
      title: "20 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 21,
      title: "21 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 22,
      title: "22 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 23,
      title: "23 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 24,
      title: "24 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 25,
      title: "25 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
  ];

  const news1 = [
    {
        id: 1,
        title: "1 The standard Lorem Ipsum passage, used since the 1500s",
        time: "5m ago",
    },
    {
        id: 2,
        title: "2 Section 1.10.33 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC",
        time: "5m ago",
    },
    {
        id: 3,
        title: "3 It has survived not only five centuries",
        time: "5m ago",
    },
    {
      id: 4,
      title: "4 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 5,
      title: "5 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 6,
      title: "6 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 7,
      title: "7 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 8,
      title: "8 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 9,
      title: "9 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 10,
      title: "10 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 11,
      title: "11 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 12,
      title: "12 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 13,
      title: "13 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 14,
      title: "14 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 15,
      title: "15 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 16,
      title: "16 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 17,
      title: "17 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 18,
      title: "18 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 19,
      title: "19 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
  ];

  const news2 = [
    {
        id: 1,
        title: "Contrary to popular belief, Lorem Ipsum is not simply random text.",
        time: "8m ago",
    },
    {
        id: 2,
        title: "There are many variations of passages of Lorem Ipsum available",
        time: "8m ago",
    },
    {
        id: 3,
        title: "Please email us with details if you can help.",
        time: "8m ago",
    },
    {
      id: 4,
      title: "4 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 5,
      title: "5 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 6,
      title: "6 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 7,
      title: "7 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 8,
      title: "8 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 9,
      title: "9 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 10,
      title: "10 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 11,
      title: "11 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 12,
      title: "12 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "3m ago",
    },
    {
      id: 13,
      title: "13 Most commen mistakes on selecting token for portfolio",
      time: "4m ago",
    },
    {
      id: 14,
      title: "14 TRON is one of the fastest-growing public chains in the world.",
      time: "3m ago",
    },
    {
      id: 15,
      title: "15 Infamouse tokens that can grow 4x untill end of the 2023 ",
      time: "4m ago",
    },
    {
      id: 16,
      title: "16 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 17,
      title: "17 Most commen mistakes on selecting token for portfolio",
      time: "3m ago",
    },
    {
      id: 18,
      title: "18 TRON is one of the fastest-growing public chains in the world.",
      time: "5m ago",
    },
    {
      id: 19,
      title: "19 Infamouse tokens that can grow 4x untill end of the 2025 ",
      time: "5m ago",
    },
  ];

  const news3 = [
    {
        id: 1,
        title: "These cases are perfectly simple and easy to distinguish.",
        time: "2m ago",
    },
    {
        id: 2,
        title: "consequatur aut perferendis doloribus asperiores repellat",
        time: "2m ago",
    },
    {
        id: 3,
        title: "It has survived Please email us with details if you can help.",
        time: "2m ago",
    },
  ];

  const firstTabnewsData = React.useMemo(() => {
    if (news.length > 0) {
      return [...news];
    }
    return [];
  }, [news]);

  const secondTabnewsData = React.useMemo(() => {
    if (news1.length > 0) {
      return [...news1];
    }
    return [];
  }, [news1]);

  const thirdTabnewsData = React.useMemo(() => {
    if (news2.length > 0) {
      return [...news2];
    }
    return [];
  }, [news2]);

  useEffect(() => {
    const handlescroll = event => {
      event.preventDefault();
      element.scrollLeft += event.deltaY;
    }

    const element = tabRef.current.querySelector('.MuiTabs-flexContainer');
    element.addEventListener('wheel', handlescroll);

    return () => {
      element.removeEventListener('wheel', handlescroll);
    }
  }, [])

  const handleShowMore = () => {
    setShowMore(true);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowMore(false);
  }

  return (
    <>
      <div className="tab-content-detail d-flex flex-row justify-content-between m_35 mb-25">
        <h1 className="font-poppins fs-15 text-white fw-500 lh-25">What’s happening</h1>
        <UniButton
          text="See All"
          fontSize={12}
          borderColor="transparent"
          buttonStyle={"seeall-btn"}
          onClick={handleShowMore}
        />
      </div>
      <div className="news-tab-root">
        <AppBar position="static" color="default">
          <Tabs
            ref={tabRef}
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{   
              style: {
                  display: "none",
              },
            }}
          >
            {
              tabs.map((item, i) => {
                return (
                <Tab label={item?.title} {...a11yProps(1)}  key={i}/>)
              })
            }
          </Tabs>
        </AppBar>
        <NewsListTabItem value={value} index={0}>
          <BreakingBox/>
          <CustomInfiniteScroll
            TabNewsArray={firstTabnewsData}
            showMore={showMore}/>
        </NewsListTabItem>
        <NewsListTabItem value={value} index={1}>
        <CustomInfiniteScroll
            TabNewsArray={secondTabnewsData}
            showMore={showMore}/>
        </NewsListTabItem>
        <NewsListTabItem value={value} index={2}>
        <CustomInfiniteScroll
            TabNewsArray={thirdTabnewsData}
            showMore={showMore}/>
        </NewsListTabItem>
        <NewsListTabItem value={value} index={3}>
        <div className="news-deatils mb-25">
          {
            news3.map((item, i) => {
              return (
                <News
                  news={item}
                  key={i}
                />
              )
            })
          }
          </div>
        </NewsListTabItem>
        <NewsListTabItem value={value} index={4}>
          Item Five
        </NewsListTabItem>
        <NewsListTabItem value={value} index={5}>
          Item Six
        </NewsListTabItem>
        <NewsListTabItem value={value} index={6}>
          Item Seven
        </NewsListTabItem>
      </div>
    </>
  );
}

export default NewsTab;

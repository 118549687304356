import React, { useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../Loader";
import News from "../News/News";
import TickCircleIcon from "../../../assets/images/tick-circle.png";

const CustomInfiniteScroll = props => {
  const {TabNewsArray,showMore} = props;
  const loadItemCount = 10;
  const [startnews, setStartnews]=useState(1)
  const [newsListData, setnewsListData] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  
  useEffect(() => {
    setTotalItemCount(TabNewsArray.length);
    setnewsListData(TabNewsArray.slice(0, loadItemCount));
  }, [
    setnewsListData,
    setTotalItemCount,
  ]);
  const handleLoadMore = React.useCallback(() => {
    if (
      newsListData.length > 1 &&
      TabNewsArray.length > 0 &&
      newsListData.length !== TabNewsArray.length
    ) {
      setTimeout(() => {
        const nextStartingPoint = (startnews) * loadItemCount;
        setnewsListData(
          newsListData.concat(TabNewsArray.splice(nextStartingPoint, loadItemCount))
        );
        setStartnews(startnews + 1);
      }, 2000);
    }
  }, [
    setnewsListData,
    newsListData,
    setStartnews,
    startnews
  ]);

  return (
    <>
    {
      showMore === true ?
      <div className="news-deatils mx-24">
        <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={newsListData.length !== totalItemCount}
        loader={<Loader/>}
        >
        {
          newsListData &&
          newsListData.length > 0 &&
          newsListData.map((item, i) => {
            return (
            <News
              news={item }
              key={i}
            />
            )
          })
        }
        </InfiniteScroll>
      </div> 
      :
      <div className="news-deatils mx-24">
      {
        TabNewsArray.slice(0,10).map((item, i) => {
            return (
            <News
              news={item }
              key={i}
            />
            )
        })
      }
      </div>
    }
    {
      newsListData.length == totalItemCount &&
      <div className="complete-data-container d-flex flex-column align-items-center">
        <img src={TickCircleIcon}/>
        <div className="font-poppins fs-15 text-white fw-500 lh-25 text-center mt-3">You're up to date. </div>
        <div className="font-poppins fs-12 text-blue fw-400 lh-18 text-center mt-2">Tap Discover for more great stories.</div>
      </div>
    }
    </>
  );
}

export default CustomInfiniteScroll;

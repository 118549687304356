import React, {useState} from "react";
import "./BreakingBox.scss";
import WallParer from "../../../../assets/images/wallpaper.jpg";
import HillClimbRacing from "../../../../assets/images/hill-climb-racing.png";
import UniButton from "../../../../common/components/UniButton";
import BreakingBoxPopUp from "../BreakingBoxPopUp/BreakingBoxPopUp";

const BreakingBox = props => {
  // const { onClick } = props;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="box-container">
        <div className="breaking-box" onClick={handleShow} style={{backgroundImage: "url("+WallParer+")"}}>
            <div className="content d-flex flex-column">
              <p className="s1-t1">COMPITITION</p>
              <h1 className="s1-t2">Breaking box</h1>
              <p className="s1-t3">Smash those crates!</p>
            </div>
        </div>
        <div className="bottom-btn-container">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row">
              <img src={HillClimbRacing} width={"40px"} height={"40px"} alt="hillclimb"/>
              <div className="d-flex flex-column justify-content-center pl-2">
                <div className="s2-t1">Hill Climb Racing 2</div>
                <div className="s2-t2">Description about promote goes here</div>
              </div>
            </div>
            <div className='get-btn'>
              <UniButton 
                text="GET" 
                background="#28292D" 
                borderColor='transparent'
                fontSize={12}
                textColor={"text-white"}
                buttonStyle={"box-UniButton"}
              />
            </div>
          </div>
        </div>
      </div>
    <BreakingBoxPopUp 
      show={show}
      onClose={() => setShow(false)}
    />
    </>
  );
};


export default BreakingBox;

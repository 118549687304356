import React from "react";
import "./BreakingBoxPopUp.scss";
import WallParer from "../../../../assets/images/wallpaper.jpg";
import HillClimbRacing from "../../../../assets/images/hill-climb-racing.png";
import SocialShare from "../../../../assets/images/share-icon.png";
import CloseIcon from "../../../../assets/images/closeIcon.png";
import UniButton from "../../../../common/components/UniButton";
import { Modal } from "react-bootstrap";

const BreakingBoxPopUp = props => {
  const {  show, onClose } = props;
  const shareData = {
    title: 'Breaking box',
    text: 'Drive and smash your way to the finish line! more content about this promotion!',
    url: 'https://developer.mozilla.org'
  }

  const handledatashare = () => {
    navigator.share(shareData);
  }

  return (
  <Modal
    show={show}
    centered
    className="modal-view"
    onHide={onClose}
  >
    <>
      <div className="breakingboxpopup-container">
        <div className="box-popup-breaking-box" style={{backgroundImage: "url("+WallParer+")"}}>
            <div className="box-popup-top-btn-container d-flex flex-row justify-content-between">
            <UniButton 
                  text="Happening now" 
                  background="#ffffff33" 
                  borderColor='transparent'
                  buttonStyle={"popupbox-leftbutton fs-12"}
                  // prependIcon={CloseIcon}
                  fontSize={12}
                  onClick={onClose}
                />
                {/* <div className="left-btn text-white">Happening now</div> */}
                {/* <Button className="close-btn text-black"  onClick={onClose}>
                    X
                </Button> */}
                <UniButton 
                  // text="GET" 
                  background="#ffffffb5" 
                  borderColor='transparent'
                  buttonStyle={"popupbox-closebutton"}
                  prependIcon={CloseIcon}
                  onClick={onClose}
                />
            </div>
            <div className="box-popup-content d-flex flex-column">
                <p className="box-popup-s1-t1">COMPITITION</p>
                <div className="d-flex flex-row justify-content-between">
                  <h1 className="box-popup-s1-t2">Breaking box</h1>
                  <UniButton 
                    prependIcon={SocialShare}
                    width={24}
                    height={24}
                    buttonStyle={"share-btn"}
                    onClick={handledatashare}
                    />
                </div>
                <p className="box-popup-s1-t3">Drive and smash your way to the finish line! more content about this promotion</p>
                <div className="bottom-border"></div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row ">
                    <img src={HillClimbRacing} width={"40px"} height={"40px"}/>
                    <div className="d-flex flex-column justify-content-center pl-2">
                      <div className="box-popup-s2-t1">Hill Climb Racing 2</div>
                      <div className="box-popup-s2-t2">Description about promote goes here</div>
                    </div>
                  </div>
                  <div className='get-btn'>
                    <UniButton 
                      text="GET" 
                      background="#ffffff33" 
                      borderColor='transparent'
                      fontSize={13}
                      buttonStyle={"popupbox-UniButton"}
                    />
                  </div>
                </div>
            </div>
        </div>
      </div>
    </>
    </Modal>
  );
};


export default BreakingBoxPopUp;
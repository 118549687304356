import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";
export const reducers = history =>
  combineReducers({
    router: connectRouter(history),
  });

export const rootSaga = function*() {
  yield all([
  ]);
};

// import { colors } from "@material-ui/core";
import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.scss";

const Loader = () => (
  <div className="loader">
    <div className="position">
      <Spinner animation="border" style={{ width: "2rem", height: "2rem", color: "white"}} />
    </div>
  </div>
);

export default Loader;
import React from "react";
import "./RoundIcon.scss";
import BitcoinIcon from "../../../assets/images/bitcoin.png";

const RoundIcon = props => {
  // const { imgUrl } = props;
  return (
    <div className="round-color">
      <img src={BitcoinIcon}/>
    </div>
    
  );
};

export default RoundIcon;

import React from "react";
import LineChart from "../LineChart/LineChart";

import RoundIcon from "../RoundIcon/RoundIcon";
import "./CurrencyCoinListItem.scss";

const CurrencyCoinListItem = props => {
  const {  currencyData, isOpenDrawer } = props;
  return (
    <>
    { 
      isOpenDrawer ? 
      <div className="market-currency-root d-flex flex-row justify-content-between">
        <div className="left-market-detail d-flex flex-column">
            <div className="currency-title">{currencyData.title}</div>
            <div className="currency-title fs-14 fw-500">${currencyData.currentAmout}</div>
        </div>
        <div className=" d-flex flex-column text-right">
          <div className="market-chart-content ">
            <LineChart
            seriesData = {currencyData.seriesData}
            seriesColor = {currencyData.color}/>
          </div>
          <div className={`currency-subtitle ${currencyData.color === "true" ? 'text-blue' : 'text-red'}`}>{currencyData.changeAmount}</div>
        </div>
      </div>
      :
      <div className="currency-root d-flex flex-row w-100 justify-content-between">
        <div className="image-container">
          <RoundIcon/>
        </div>
        <div className="detail-content d-flex flex-column w-100 ml-12">
          <div className="d-flex flex-row justify-content-between">
              <div className="left-detail d-flex flex-column">
                  <div className="currency-title">{currencyData.title}</div>
                  <div className="currency-subtitle text-light-gray">{currencyData.subTitle}</div>
              </div>
              {/* <div className="d-flex flex-row justify-content-between "> */}
                <div className="chart-content">
                  <LineChart
                  seriesData = {currencyData.seriesData}
                  seriesColor = {currencyData.color}/>
                </div>
                <div className="right-detail d-flex flex-column text-right">
                    <div className="currency-amount">${currencyData.currentAmout}</div>
                    <div className={`currency-subtitle ${currencyData.color == "true" ? 'text-blue' : 'text-red'}`}>{currencyData.changeAmount}</div>
                </div>
              {/* </div> */}
          </div>
          <div className="bottom-border"></div>
        </div>
      </div>
    }
    </>
  );
};
export default CurrencyCoinListItem;
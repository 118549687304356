import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  authRoutes,
  // freeRoutes,
  // routePaths,
  // signUpRoutes,
  // unAuthRoutes
} from "../routes";
import AppLayout from "../common/components/AppLayout";

const LoggedInRoutes = () =>
  authRoutes.map((r, i) => (
    <Route
      key={i}
      path={r.path}
      exact={r.exact}
      render={props => <r.main {...props} />}
    />
  ));

const Main = React.memo(props => {
  const history = useHistory()

  return (
    <>
      {/* <InternetError /> */}
      <AppLayout>
        <Switch>
          {
            LoggedInRoutes()
          }
        </Switch>
        
      </AppLayout>
    </>
  );
});

export default Main;
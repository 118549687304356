import React from "react";
import "./SignupHeader.scss";
import clsx from "clsx";


const SignupHeader = React.memo(props => {
  const { avtar, title, subTitle, customStyle } = props;
  // console.log('customStyle',customStyle)
  return (
    <div className="signup-header">
      {avtar && <div className="logo-container"><img src={avtar}></img></div>}
      {title && <div className="header-title text-center">{title}</div>}
      {subTitle && <div 
      className={clsx(
        "header-subtitle",
        customStyle
      )
      }
      >{subTitle}</div>}
    </div>
  );
});

export default SignupHeader;

import React from 'react'
import './Premium.scss';
import PremumLogo from '../../assets/images/Premium/premium-icon.svg'
import rightArrow from '../../assets/images/Premium/right-arrow.svg'
import UniButton from '../../common/components/UniButton/UniButton'
import FooterTab from '../../common/components/FooterTab';
import PageLayout from "../../common/components/AppLayout/PageLayout";

function Premium() {
    return (
        <>
            <PageLayout divClass="premium-screen align-items-center">
                <div className='premium-sub-title'>
                    <h1 className='header-title8 '>Premium</h1>
                    <div className='image1'>
                        <img src={PremumLogo} alt="logo" width={140} height={140} />
                    </div>
                    <PageLayout header={<h2 className='header-title3'>Welcome to Unidef permium</h2>} />
                    <div className='sub-title mx-auto'>
                        Stay up to date on important Stories with top news
                    </div>
                    <div className='main'>
                        <div className='innerTitle'>
                            <img src={rightArrow} alt="logo" width={23} height={23} />
                            <div className='content'>
                                TRON is one of the fastest-growing public chains<br /> in the world.
                            </div>
                        </div>
                        <div className='innerTitle'>
                            <img src={rightArrow} alt="logo" width={23} height={23} />
                            <div className='content'>
                                TRON is one of the fastest-growing public chains<br /> in the world.
                            </div>
                        </div>
                        <div className='innerTitle'>
                            <img src={rightArrow} alt="logo" width={23} height={23} />
                            <div className='content'>
                                TRON is one of the fastest-growing public chains<br /> in the world.
                            </div>
                        </div>
                    </div>
                    <div className='turnOn2 mx-auto'>
                        <UniButton
                            text="Try for free"
                            buttonStyle={"Premium-btn"}
                            textStyle={"PremiumText"}
                        />
                        {/* <UniButton text='Try for free' background="#FFD700" textColor="#000000" height={56} width={246} /> */}
                    </div>
                </div>
            </PageLayout>
            <FooterTab
                currentTab={5}
            />
        </>
    )
}

export default Premium;
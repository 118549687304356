import React from 'react'
import './Notification.scss';
import notificationLogo from '../../assets/images/notification/notification-logo.svg'
import UniButton from '../../common/components/UniButton/UniButton'
import FooterTab from '../../common/components/FooterTab';
import PageLayout from "../../common/components/AppLayout/PageLayout";

function Notification() {
  return (
    <>
      <PageLayout divClass="Notification-screen align-items-center">
          <div className='sub-title4'>
            <h1 className='header-title8'>Notification</h1>
            <div className='image'>
              <img src={notificationLogo} alt="logo" width={140} height={140} />
            </div>
            <PageLayout header={<h1 className='header-title3'>Instant notifications</h1>} />
            <div className='sub-content'>
              Stay up to date on important Stories with top news
            </div>
            <div className='sub-content m-0'>
              To receive notifications as they happen,turn on push notifications. You’ll also receive them when you’re not on UnidefTimes.com. Turn them off anytime.
            </div>
            <div className='turnOn mx-auto'>
              <UniButton
                text="Turn on notifications"
                buttonStyle={"Notification-btn"}
                textStyle={"NotificationText"}
              />
            </div>
          </div>
      </PageLayout>
      <FooterTab
        currentTab={4}
      />
    </>
  )
}
export default Notification
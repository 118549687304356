import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isMobile } from "react-device-detect";
import "./AppLayout.sass";

const AppLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col
        lg={isMobile ? 12 : 5}
        sm={12}
        md={isMobile ? 12 : 5}
        className="mx-auto p-0"
      >
        {children}
      </Col>
    </Row>
  </Container>
);

export default AppLayout;

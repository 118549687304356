import React from "react";
import Button from "react-bootstrap/Button";
import "./UniButton.scss";
import clsx from "clsx";

const UniButton = props => {
  const {
    text,
    onClick,
    disabled,
    buttonStyle,
    prependIcon,
    textLeftAlign,
    textColor,
    borderColor,
    textStyle,
    height,
    width,
    background,
    fontSize,
    btnSize
  } = props;
  return (
    <Button
      variant="dark"
      size={btnSize}
      className={clsx(
        "custom-btn-container",

        buttonStyle,
        textLeftAlign && "text-align-left",
        disabled && "pointer-none",
      )
      }
      onClick={onClick}
      disabled={disabled}
      style={{ borderColor: borderColor && borderColor, height: height && height, width: width && width, 
        background: background && background }}
    >
      {
       prependIcon && 
        <span className="btn-prepend-icon" >
          <img src = {prependIcon} />
        </span>
      }
      {
        text && 
        <span className={clsx("btn-text", textStyle, disabled && "disable-btn-text")} style={{ color: textColor && textColor, fontSize : fontSize && fontSize}}>
          {text}
        </span>
      }   
    </Button>
  );
};

export default UniButton;
import React from "react";
import "./HomeScreen.scss";
import FooterTab from "../../common/components/FooterTab";
import SignupHeader from "../../common/components/SignupHeader";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import CurrencyCoinListItem from "../../common/components/CurrencyCoinListItem";
import NewsListTabItem from "./components/NewsListTabItem";
import UnidefLogo from "../../assets/images/Home-pageIcon/logo-home.svg"

const HomeScreen = props => {
  const currencyData = [
    {
        id: 1,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "BTC",
        subTitle: "Bitcoin",
        currentAmout: "20,454.59",
        changeAmount: "+2.00%",
        color:"true",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 2,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "U",
        subTitle: "UnidefDAO",
        currentAmout: "1,236",
        changeAmount: "+1.36%",
        color:"true",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 3,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "BNB",
        subTitle: "BNB Smart Chain",
        currentAmout: "213,459",
        changeAmount: "-1.00%",
        color:"false",
        seriesData:[10, 20, 55, 51, 20, 62, 79, 90, 10]
    },
    ];
  return (
    // <div className="comman-container">
      <PageLayout divClass="home-screen">
        <div className="home-block">
          <SignupHeader
            avtar={UnidefLogo}
            subTitle="One media, All things decentralized economy"
            customStyle="mt-2"
          />
          <div className="currencycoinlist-backgound">
          {
            currencyData?.map((item, i) => {
              return (
                <CurrencyCoinListItem 
                  currencyData={item}
                  key={i}
                />
              )
            })
          }
          </div>
          <NewsListTabItem/>
        </div>
        <FooterTab
        currentTab={1}/>
      </PageLayout>
    // </div>
  );
};
export default HomeScreen;
import React from 'react'
import './Discover.scss'
import MarketDefault from './component/MarketDefault/MarketDefault';
import FooterTab from '../../common/components/FooterTab';
import PageLayout from "../../common/components/AppLayout/PageLayout";

const Discover = props => {
  const discoverContent = [
    {
      id: 1,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 2,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 3,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 4,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 5,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 6,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 7,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
    {
      id: 8,
      title: "UnidefDAO",
      subTitle: "Sub text goes here",
    },
  ];
  return (
    <>
      <PageLayout divClass="discover-screen" header={<h1 className='title-main'>Discover</h1>}>
        <div className='checkBox'>
          {
            discoverContent?.map((item, i) => {
              return (
                <>
                  <MarketDefault
                    discoverData={item}
                    key={i}
                  />
                </>
              )
            })
          }
        </div>
      </PageLayout>
      <FooterTab
        currentTab={3}
      />
    </>
  )
}

export default Discover


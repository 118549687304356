import React, { useEffect } from "react";
import './SplashScreen.scss';
import SignupHeader from "../../common/components/SignupHeader/SignupHeader";
import { useHistory } from 'react-router-dom';
import { routePaths } from '../../routes';
import bottomIcon from '../../assets/images/splash Screen/bottom-icon.svg';
import UnidefLogoIcon from '../../assets/images/splash Screen/Logo-Firstscreen.svg';

const SplashScreen = () => {
    const history = useHistory()
    useEffect(() => {
        setTimeout(() => {
            history.push(routePaths.SignUp)
        }, 3000)
    }, [])

    return (
        <div className="App">
            <header className="App-header">
                <div className='title'>
                    <SignupHeader 
                    avtar={UnidefLogoIcon}/>
                </div>
                <div className='footer flex-column'>
                    <p className='footer-text'>from</p>
                    <img className="bottonIcon" src={bottomIcon} alt="bottom Icon"/>
                </div>
            </header>
        </div>
    );
}
export default SplashScreen;
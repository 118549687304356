import React from 'react';
import './SignUp.scss'
import SignupHeader from '../../common/components/SignupHeader/SignupHeader';
import Instagram from '../../assets/images/First Screen/instagram.svg';
import Twitter from '../../assets/images/First Screen/twiter.svg';
import FaceBook from '../../assets/images/First Screen/facebook.svg';
import UniButton from '../../common/components/UniButton/UniButton';
import { routePaths } from '../../routes';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../common/components/AppLayout/PageLayout';
import LogoFirstScreen from '../../assets/images/First Screen/Logo-Firstscreen.svg'

const SignUp = React.memo(props => {
    const history = useHistory()

    const skip = () => {
        history.push(routePaths.homeScreen)
    }
    return (
        <PageLayout divClass="signUp">
            <div className="signUpHeader">
                <div className='top-left' >
                    <UniButton
                        text="skip"
                        buttonStyle={"skip-btn"}
                        borderColor="transparent"
                        onClick={skip}
                    />
                    {/* <UniButton text='skip' height="33px" width="80px" onClick={skip} /> */}
                </div>
                <div className='middle-content'>
                    <SignupHeader 
                    avtar={LogoFirstScreen}
                    title="one media,"
                    subTitle="All things decentralized economy"/>
                    {/* <PageLayout header={<h1 className='header-title4'>One media,</h1>} />
                    <div className='sub_content'>
                        All things decentralized economy
                    </div> */}
                </div>
                <div className='footer-icon mx-auto'>
                    <UniButton
                        text="Follow us on Instagram"
                        prependIcon={Instagram}
                        textStyle={"InstagramText"}
                        buttonStyle={"instagram-btn"}
                    />
                    <UniButton
                        text="Follow us on twitter"
                        prependIcon={Twitter}
                        textStyle={"TwitterText"}
                        buttonStyle={"Twiter-btn"}
                    />
                    <UniButton
                        text="Follow us on facebook"
                        prependIcon={FaceBook}
                        textStyle={"FacebookText"}
                        buttonStyle={"facebook-btn"}
                    />
                </div>
            </div>
        </PageLayout>
    )
})

export default SignUp
import React from 'react';
import './MarketDefault.scss'
import UnidefIcon from '../../../../assets/images/MarketDefault/unideficon.svg';
import TwitterIcon from '../../../../assets/images/MarketDefault/Twitter.svg';
import UniButton from '../../../../common/components/UniButton';

const MarketDefault = React.memo(props => {
  const {  discoverData } = props;

  // console.log(discoverData);
  return (
    <div className="Market-default justify-content-between d-flex  align-items-center flex-row ">
      <div className="d-flex logo-container">
        <img className='unidefIcon' src={UnidefIcon} alt="Unidef Icon" />
        <img className='TwitterIcon' src={TwitterIcon} alt="Twitter Icon" />
      </div>
      <div className='d-flex content flex-column ml-2'>
        {discoverData && <div className="header-title">{discoverData.title}</div>}
        {discoverData && <div className="header-subtitle">{discoverData.subTitle}</div>}
      </div>
      <div className='buttonFollow d-flex align-items-center'>
        <UniButton className="follow" text='Follow' background="#FFD700" height={35} textColor="#000000" fontSize={12} />
      </div>
    </div>
  );
});

export default MarketDefault
import React from "react";
// import LineChart from "../LineChart/LineChart";
import RoundIcon from "../RoundIcon/RoundIcon";
import "./News.scss";

const News = props => {
  const { news } = props;
  return (
    <>
      <div className="news-root d-flex flex-row w-100">
        <div className="new-image-container ">
          <RoundIcon />
        </div>
        <div className="news-detail-content d-flex flex-column w-100 ml-12">
          <div className="right-section">
            <div className="right-title">
              {news.title}
            </div>
            <div className="right-subtitle">{news.time}</div>
          </div>
          <div className="news-bottom-border"></div>
        </div>
      </div>
    </>
  );
};

export default News;
import React, {useEffect, useState} from "react";
// import clsx from "clsx";
import "./FooterTab.scss";
import { routePaths } from "../../../routes";
import { useHistory } from "react-router-dom";

import ChartIcon from "../../../assets/images/footertabs/chart-disabled-icon.png";
import SearchIcon from "../../../assets/images/footertabs/search-disabled-icon.png";
import NotificationIcon from "../../../assets/images/footertabs/notification-disabled-icon.png";
import PremiumIcon from "../../../assets/images/footertabs/premium-disabled-icon.png";
import HomeActiveIcon from "../../../assets/images/footertabs/home-active-icon.png";
import ChartActiveIcon from "../../../assets/images/footertabs/chart-active-icon.png";
import SearchActiveIcon from "../../../assets/images/footertabs/search-active-icon.png";
import NotificationActiveIcon from "../../../assets/images/footertabs/notification-active-icon.png";
import PremiumActiveIcon from "../../../assets/images/footertabs/premium-active-icon.png";
import HomeIcon from "../../../assets/images/footertabs/home-disabled-icon.png";
const FooterTab = props => {
  const { currentTab } = props;
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(currentTab);

  useEffect(() => {
    setActiveTab(currentTab)
  }, [currentTab]);

  const handleTabChange = tabIndex => {
    switch (tabIndex) {
      case 1:
        history.push(routePaths.homeScreen);
        break;
      case 2:
        history.push(routePaths.marketScreen);
        break;
      case 3:
        history.push(routePaths.Discover);
        break;
      case 4:
        history.push(routePaths.Notification);
        break;
      case 5:
        history.push(routePaths.Premium);
        break;
      default:
        // return true;
        break;
    }
  };
  return (
    <div className="footer-tab-container col-md-5 col-lg-5 p-0">
      <div className="content">
        <div className="footer-tab" onClick={() => handleTabChange(1)}>
          <img src={activeTab === 1 ? HomeActiveIcon : HomeIcon} width={20} height={20} />
          <span className={activeTab === 1 ? "active-tab-text" : "footer-text"}>Home</span>
        </div>
        <div className="footer-tab" onClick={() => handleTabChange(2)}>
        <img src={activeTab === 2 ? ChartActiveIcon : ChartIcon} width={20} height={20} />
          <span className={activeTab === 2 ? "active-tab-text" : "footer-text"}>Market</span>
        </div>
        <div className="footer-tab" onClick={() => handleTabChange(3)}>
          <img src={activeTab === 3 ? SearchActiveIcon : SearchIcon} width={20} height={20} />
          <span className={activeTab === 3 ? "active-tab-text" : "footer-text"}>Discover</span>
        </div>
        <div className="footer-tab" onClick={() => handleTabChange(4)}>
        <img src={activeTab === 4 ? NotificationActiveIcon  : NotificationIcon} width={20} height={20} />
          <span className={activeTab === 4 ? "active-tab-text" : "footer-text"}>Notification</span>
        </div>
        <div className="footer-tab" onClick={() => handleTabChange(5)}>
          <img src={activeTab === 5 ? PremiumActiveIcon : PremiumIcon} width={20} height={20} />
          <span className={activeTab === 5 ? "active-tab-text" : "footer-text"}>Premium</span>
        </div>
      </div>
    </div>
  );
};
export default FooterTab;
import React from "react";
import Chart from "react-apexcharts";

const LineChart = props => {
  const { seriesData, seriesColor } = props;
  // console.log('seriesData',seriesColor)
    const options= {
        chart: {
          height: '25px',
          width: '80px',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: seriesColor === "true" ? ["#42AAFF"] : ["#FF4D4F"],
        fill: {
          colors: seriesColor === 'true' ? '#42AAFF' : '#FF4D4F',
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          }
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        }
      };
     const series= [{
      name: "Desktops",
      data: seriesData
  }];

    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart" width="72px" height="25px">
            <Chart
              options={options}
              series={series}
              type="area"
              width="100"
            />
          </div>
        </div>
      </div>
    );
  }

export default LineChart;
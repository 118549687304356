import React from "react";
import "./MarketScreen.scss";
import FooterTab from "../../common/components/FooterTab";
import PageLayout from "../../common/components/AppLayout/PageLayout";
import CurrencyCoinListItem from "../../common/components/CurrencyCoinListItem";
import { Global } from '@emotion/react';
import { styled } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';    
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import News from "../../common/components/News/News";
import UniButton from "../../common/components/UniButton";


const MarketScreen = props => {
  const news = [
    {
        id: 1,
        title: "TRON is one of the fastest-growing public chains in the world.",
        time: "3m ago",
    },
    {
        id: 2,
        title: "Infamouse tokens that can grow 4x untill end of the 2023 ",
        time: "3m ago",
    },
    {
        id: 3,
        title: "Most commen mistakes on selecting token for portfolio",
        time: "3m ago",
    },
    {
        id: 4,
        title: "TRON is one of the fastest-growing public chains in the world.",
        time: "3m ago",
    },
    {
        id: 5,
        title: "Infamouse tokens that can grow 4x untill end of the 2023 ",
        time: "3m ago",
    },
    {
        id: 6,
        title: "Most commen mistakes on selecting token for portfolio",
        time: "3m ago",
    },
    {
        id: 7,
        title: "TRON is one of the fastest-growing public chains in the world.",
        time: "3m ago",
    },
    {
        id: 8,
        title: "Infamouse tokens that can grow 4x untill end of the 2023 ",
        time: "3m ago",
    },
    {
        id: 9,
        title: "Most commen mistakes on selecting token for portfolio",
        time: "3m ago",
    },
  ];
  const marketData = [
    {
        id: 1,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "BTC",
        subTitle: "Bitcoin",
        currentAmout: "20,454.59",
        changeAmount: "+2.00%",
        color:"false",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 2,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "U",
        subTitle: "UnidefDAO",
        currentAmout: "1,236",
        changeAmount: "+1.36%",
        color:"true",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 3,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "BNB",
        subTitle: "BNB Smart Chain",
        currentAmout: "213,459",
        changeAmount: "-1.00%",
        color:"false",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
      id: 4,
      // imgUrl: "../../../assets/images/logos_bitcoin.png",
      title: "USDT",
      subTitle: "Tether",
      currentAmout: "1.00",
      changeAmount: "+0.021%",
      color:"true",
      seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 5,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "ADA",
        subTitle: "Cardano",
        currentAmout: "0.0722",
        changeAmount: "-2.12%%",
        color:"true",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 6,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "DOGE",
        subTitle: "Dogecoin",
        currentAmout: "0.0722",
        changeAmount: "-1.00%",
        color:"false",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
    id: 7,
    // imgUrl: "../../../assets/images/logos_bitcoin.png",
    title: "BTC",
    subTitle: "Bitcoin",
    currentAmout: "20,454.59",
    changeAmount: "+2.00%",
    color:"true",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 8,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "U",
        subTitle: "UnidefDAO",
        currentAmout: "1,236",
        changeAmount: "+1.36%",
        color:"false",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
        id: 9,
        // imgUrl: "../../../assets/images/logos_bitcoin.png",
        title: "BNB",
        subTitle: "BNB Smart Chain",
        currentAmout: "213,459",
        changeAmount: "-1.00%",
        color:"true",
        seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
      id: 10,
      // imgUrl: "../../../assets/images/logos_bitcoin.png",
      title: "BTC",
      subTitle: "Bitcoin",
      currentAmout: "20,454.59",
      changeAmount: "+2.00%",
      color:"false",
      seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
      id: 11,
      // imgUrl: "../../../assets/images/logos_bitcoin.png",
      title: "U",
      subTitle: "UnidefDAO",
      currentAmout: "1,236",
      changeAmount: "+1.36%",
      color:"true",
      seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
    {
      id: 12,
      // imgUrl: "../../../assets/images/logos_bitcoin.png",
      title: "BNB",
      subTitle: "BNB Smart Chain",
      currentAmout: "213,459",
      changeAmount: "-1.00%",
      color:"false",
      seriesData:[10, 41, 35, 51, 49, 62, 69, 10, 48]
    },
  ];

  const drawerBleeding = 160;
  const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor:'#28292D'
  }));

  const Puller = styled(Box)(({ theme }) => ({
    width: 50,
    height: 4,
    backgroundColor:'#60626C',
    borderRadius: 2.5,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    cursor:'pointer'
  }));
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <PageLayout divClass="market-block">
      <div className="market-screen-container">
        {
          !drawerOpen &&
          <>
            <div className="font-poppins text-white fs-18 fw-500 lh-27 text-center pt-40">Market</div>
            <div className="font-poppins text-lightgray fs-12 fw-400 lh-18 mt-2 text-center mb-25">24 June 2022, Friday</div>
          </>
        }
        <div className={`${drawerOpen && 'drawer-detail' }`}>
          {
            marketData?.map((item, i) => {
              return (
                <CurrencyCoinListItem 
                  currencyData={item}
                  key={i}
                  isOpenDrawer={drawerOpen}
                />
              )
            })
          }
        </div>
        <div className="bottomdrawer-panel-block">
          {/* <BottomDrawer/> */}
          <div className='drawer-container'>
            <CssBaseline />
            <Global
              styles={{ 
                '.MuiDrawer-root > .MuiPaper-root': {
                  height: `calc(80% - ${drawerBleeding}px)`,
                  overflow: 'visible',
                  margin: "0 auto",
                  backgroundColor: "#28292D",
                  ['@media (min-width:768px)']: {
                      width: `calc(41.666667%)`,
                  },
                },
              }}
            />
            <SwipeableDrawer
              variant='persistent'
              anchor="bottom"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              swipeAreaWidth={drawerBleeding}
              disableSwipeToOpen={false}
              ModalProps={{
              keepMounted: true,
              }}
            >
              <StyledBox
                sx={{
                  position: 'absolute',
                  top: -drawerBleeding,
                  borderTopLeftRadius: 25,
                  borderTopRightRadius: 25,
                  visibility: 'visible',
                  right: 0,
                  left: 0,
                  paddingTop: 30,
                  paddingBottom:30,
                  paddingLeft:16,
                  paddingRight:16,
                  zIndex:120
                }}
              >
                <Puller 
                onClick={toggleDrawer(true)}
                />
                <div className='heder-content d-flex flex-row justify-content-between'>
                  <div className='d-flex flex-column'>
                    <div className='drawer-title text-white font-popping font-poppins fs-15 lh-25 fw-500'>Market News</div>
                    <div className='drawer-subtitle text-white font-poppins fs-12 lh-18 fw-400'>Latest cryptocurrency news </div>
                  </div>
                  <UniButton
                    text={drawerOpen? "Close" : "See All"}
                    fontSize={12}
                    borderColor="transparent"
                    buttonStyle={"seeall-btn"}
                    onClick={toggleDrawer(true)}
                  />
                </div>
                <div className="drawer-news-deatils mx-24">
                  {
                      news.map((item, i) => {
                      return (
                          <News
                          news={item }
                          key={i}
                          />
                      )
                      })
                  }
              </div>
              </StyledBox>
            </SwipeableDrawer>
          </div>
        </div>
      </div>
      <FooterTab
        currentTab={2}
      />
    </PageLayout>
  );
};


export default MarketScreen;

